import React, { useMemo } from "react";

import "./aboutus.css";
import { useMediaQuery } from "react-responsive";

export default function AboutUs() {
  return (
    <div className="about-us-container">
      <div className="header-image">
        <img src="about-us.jpg" alt="Header" />
      </div>
      <div className="content-container">
        <div className="left-section">
          <p className="let-us-know-text">Let Know Us:</p>
        </div>
        <div className="right-section">
          <div className="rounded-border-content">
            <p>
              Glassco Paint, operating within the framework of 'Pakistan Paints
              & Chemicals' (PPC), is synonymous with excellence under the brand
              name 'Industrial Care.' Renowned for innovative, high-performance
              coatings, Glassco's commitment extends beyond delivering
              protective and aesthetically pleasing solutions. Specializing in
              corrosion control and intumescent coatings, our industrial paints
              feature common polymers like polyurethane, epoxy, fluoropolymer,
              and moisture cure urethane.
            </p>
            <p>
              At Glassco Paint, we empower customers to achieve their industrial
              coating objectives. More than just a supplier, we believe in
              ensuring optimal results. We offer comprehensive product
              knowledge, coupled with on-site assistance, enabling our customers
              to master the best application methods. Expect customer-friendly
              service and unwavering support as you navigate your coating needs
              with Glassco Paint.
            </p>
          </div>
        </div>
      </div>
      <IconTable />
      <div className="research-section">
        <div className="research-content">
          <h2>Research & Development</h2>
          <p>
            Glassco Paint is dedicated to continuous innovation and research,
            exploring advancements from pre-treatment and electrocoat to liquid,
            powder, and cutting-edge options like ultra-violet (UV) and
            electro-beam (EB) coatings. Our commitment to technological
            evolution ensures that Glassco Paint is poised to tackle any
            coatings challenge, providing you with state-of-the-art solutions
            for your projects. Stay tuned as Glassco continues to push
            boundaries in the paint industry, meeting and exceeding diverse
            coating needs.
          </p>
        </div>
      </div>
    </div>
  );
}

const IconTable = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const iconData = useMemo(
    () => [
      {
        icon: "highperformance.jpg",
        content:
          "Glassco Paint's commitment extends beyond aesthetics, prioritizing eco-friendly formulations that underscore environmental responsibility. With a diverse palette, our paints offer both timeless classics and contemporary hues, providing customers the freedom to express their unique style. Backed by innovation, durability, and a customer-centric approach, Glassco remains the go-to choice for transformative and enduring paint solutions.",
      },
      {
        icon: "long-range.png",
        content:
          "With an unwavering focus on innovation, Glassco Paint continually updates its palette to reflect the latest design trends, ensuring customers stay ahead in style. Our commitment to quality extends to each shade, promising not just a color but a lasting impression that transforms spaces. Whether you seek a cozy ambiance or a bold statement, Glassco's diverse range empowers you to express your unique personality through captivating and enduring paint selections.",
      },
      {
        icon: "wide-range.png",
        content:
          "Embracing versatility, Glassco Paint caters to diverse preferences, providing a spectrum that ranges from calming neutrals to vibrant accents. Our color consultants stand ready to assist, ensuring you make informed decisions that align with your vision. Whether you're revamping a cozy living room or giving your home's exterior a fresh look, Glassco's expansive color range guarantees the perfect palette for every project.",
      },
      {
        icon: "best-price.png",
        content:
          "At Glassco Paint, our commitment to affordability extends beyond pricing; we prioritize value, ensuring you receive not only cost-effective solutions but also uncompromised quality. With our pocket-friendly options, you can embark on your projects with confidence, transforming spaces without breaking the bank. Experience the perfect blend of budget-consciousness and excellence, as Glassco Paint empowers you to bring your creative visions to life affordably and stylishly.",
      },
      {
        icon: "repaint.jpg",
        content:
          "Our interior paints not only add vibrant hues to your living spaces but also boast enduring formulas that resist wear and tear, maintaining their beauty over time. Meanwhile, the exterior paints from Glassco go beyond aesthetics, forming a protective shield against the sun, rain, and other environmental factors. With our exterior paint solutions, your home not only looks appealing but stands resilient against the tests of weather, providing long-lasting charm and protection. Transform your indoor and outdoor spaces with Glassco, where aesthetics meet durability.",
      },
      {
        icon: "globe.png",
        content:
          "With a widespread network of distribution, Glassco Paint reaches every corner of the nation, bringing quality paints to your doorstep. Our commitment to nationwide availability is a testament to our dedication to customer satisfaction, ensuring that you can access our premium products effortlessly. Wherever you are, experience the convenience and excellence of Glassco Paint, making your painting projects seamless and delightful.",
      },
    ],
    []
  );

  return (
    <table>
      <tbody>
        {iconData.map((item, index) => (
          <>
            {isMobile === true ? (
              <>
                <tr key={index}>
                  <td>
                    <div className="icon-cell" data-label="Icon" role="button">
                      <div className="icon-container">
                        <img
                          className="about-us-icon"
                          src={item.icon}
                          alt={"icon"}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr key={`content-${index}`}>
                  <td data-label="Content">{item.content}</td>
                </tr>
              </>
            ) : (
              <>
                <tr key={index}>
                  <td className="icon-cell" data-label="Icon" role="button">
                    <div className="icon-container">
                      <img
                        className="about-us-icon"
                        src={item.icon}
                        alt={"icon"}
                      />
                    </div>
                  </td>
                  <td data-label="Content">{item.content}</td>
                </tr>
              </>
            )}
          </>
        ))}
      </tbody>
    </table>
  );
};
