import React, { useCallback, useMemo } from "react";
import HeadingText from "../heading-text/HeadingText";
import "./categorylist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

export default function CategoryList({ productPage, shadeColour }) {
  const paints = useMemo(
    () => [
      {
        name: " Aqueous Matt Finish",
        image: "Glassco Matt Finish Aqueous 4Ltr Bucket-a.jpg",
        details: " ",
        pdf: "GLASSCO AQUES MATT FINISH.pdf",
      },
      {
        name: "Regular Silk Emulsion",
        image: "Glassco Regular Silk Emulsion 4 Liter Bucket-a.jpg",
        details: "",
        pdf: "GLASSCO REGULAR EMULSION SILK.pdf",
      },
      {
        name: "Super Emulsion",
        image: "Glassco Super Emulsion 4 Liter Bucket-a.jpg",
        details: " ",
        pdf: "GLASSCO SUPER EMULSION WALL.pdf",
      },
      {
        name: "Semi Plastic Emulsion ",
        image: "Glassco Semi Plastic Emulsion 4 Liter Bucket-a.jpg",
        details: "",

      },
      {
        name: "Weather Sheet",
        image: "Glassco Weather Sheet Pro Series 4 Liter Bucket-a.jpg",
        details: "",
        pdf: "GLASSCO WEATHER DEFENDER red.pdf",
      },
      {
        name: "Weather Protection",
        image: "Glassco Regular Weather Coat 4 Liter Bucket-a.jpg",
        details: "",
        pdf: "GLASSCO WEATHER DEFENDER green.pdf",
      },

      {
        name: "Wall Putty Acralic",
        image: "Glassco Acralic Wall Putty 5kg Bucket-a.jpg",
        details: "",
      },
      {
        name: "Wall Putty",
        image: "paint_wall_putty.png",
        details: "",
      },
    
      {
        name: "Interior Wall Sealer ",
        image: "Glassco Interior Wall Sealer 4 Liter Bucket-a.jpg",
        details: "",
      },
      {
        name: "Exterior Wall Sealer ",
        image: "Glassco Exterior Wall Sealer 4 Liter Bucket-a.jpg",
        details: "",
      },

      {
        name: "Primum Super Enamel ",
        image: "Glassco Primum Super Emulsion 4 Liter Tin Bucket-a.jpg",
        details: "",
        pdf: "GLASSCO ENAMEL.pdf",
      },

      {
        name: "Synthetic Metallic Finish",
        image: "Glassco Synthetic Metallic Finish 4 Liter Tin Bucket-a.jpg",
        details: "",
      },
    ],
    []
  );

  const handleImageClick = useCallback((pdf) => {
    if (pdf) {
      window.location.href = pdf;
    }
  }, []);
  const showPaint = shadeColour ? !!paints.pdf : true;
  return (
    <>
      {productPage || shadeColour === true ? (
        " "
      ) : (
        <HeadingText text={"Products"} />
      )}
      <div
        className={`paint-list ${productPage ? "product-page-paint-list" : ""}`}
      >
        {paints
          .filter((paint) => !shadeColour || (shadeColour && paint.pdf))
          .map((paint) => (
            <div
              className={`paint-item ${
                shadeColour ? "shadow-colour-item" : ""
              } ${productPage ? "paint-item-product-page" : ""}`}
              onClick={() => handleImageClick(paint.pdf)}
              key={paint.name}
            >
              {shadeColour ? (
                <div>
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    className="shade-icon-pdf"
                    onClick={() => handleImageClick(paint.pdf)}
                  />
                </div>
              ) : (
                <img src={paint.image} alt={paint.name} />
              )}
              <div
                className={`content-category ${
                  shadeColour ? " shadow-content-category" : " "
                }`}
              >
                <div
                  className={`paint-name ${
                    productPage ? "product-paint-name" : ""
                  }`}
                >
                  {paint.name}
                </div>
                <div
                  className={`paint-detail ${
                    productPage ? "product-paint-detail" : ""
                  }`}
                >
                  {paint.details}
                </div>
                {shadeColour === true
                  ? " "
                  : paint.pdf && (
                      <div className="content-category-button-div">
                        <button className="content-category-button">
                          Shade Colour Pdf
                        </button>
                      </div>
                    )}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
