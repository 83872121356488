import React from "react";
import CategoryList from "../../components/category/CategoryList";
import "./productpage.css";
export default function ProductsPage() {
  const productPage = true;
  return (
    <div>
      <div className="header-image">
        <img src="home.jpg" alt="Header" />
      </div>
      <div>
        <CategoryList productPage={productPage} />
      </div>
    </div>
  );
}
