import React, { useState } from "react";

import "./contactus.css";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    city: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: value ? "" : `Please enter your ${name}.`,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const hasErrors = Object.values(formData).some((value) => !value);
    if (hasErrors) {
      setFormErrors({
        name: formData.name ? "" : "Please enter your name.",
        city: formData.city ? "" : "Please enter your city.",
        message: formData.message ? "" : "Please enter your message",
      });
      return;
    }

    const message = encodeURIComponent(
      `Name: ${formData.name} | City: ${formData.city} | Message: ${formData.message}`
    );

    const whatsappURL = `https://wa.me/3394085000?text=${message}`;

    window.open(whatsappURL, "_blank");

    console.log("Form submitted:", formData);
  };

  return (
    <>
      <div className="header-image">
        <img src="contact.jpg" alt="Header" />
      </div>
      <div className="contact-us-container">
        <div className="main-container-contact">
          <form onSubmit={handleSubmit} className="contactus-form">
            <div className="page-head-contact">
              <h1> Leave Your Message</h1>
            </div>
            <div>
              <label htmlFor="name">Your Name (required)</label>
              <input
                className="user-inputs-contact"
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <div className="error">{formErrors.name}</div>
            </div>
            <div>
              <label htmlFor="city">City</label>
              <br/>
              <input
                className="user-inputs-contact"
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              <div className="error">{formErrors.city}</div>
            </div>
            <div>
              <label htmlFor="message" className="inputs-label">
                Message
              </label>
              <textarea
                cols={"4"}
                rows={"4"}
                className="user-inputs-message"
                type="text"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <div className="error">{formErrors.message}</div>
            </div>
            <div className="dealer-submit-container">
              <button className="submit-button-dealer" type="submit">
                Send
              </button>
            </div>
          </form>
          <div>
            <iframe
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3378.855908440596!2d74.19513317563602!3d32.12719347394205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzLCsDA3JzM3LjkiTiA3NMKwMTEnNTEuOCJF!5e0!3m2!1sen!2s!4v1707214891844!5m2!1sen!2s"
              className="map-contactus"
              allowfullscreen="yes"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="contact-section">
          <div className="header">
            <span>Contact Details</span>
          </div>
          <div className="contact-info">
            <div className="info-item">
              <div>
                {" "}
                📞 <span> &nbsp; 0304 1718000</span>{" "}
              </div>
              <div>
                {" "}
                📱 <span> &nbsp; 0304 1718000 </span>{" "}
              </div>
              <div>
                {" "}
                ✉️ <span> &nbsp; glasscopaint@gmail.com</span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
