import React from "react";
import "./whatsappicon.css";
export default function WhatsAppIcon() {
  const openWhatsApp = () => {
    window.open('https://wa.me/923041718000', '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={()=> openWhatsApp()}>
      <img
        src="/whatsapp icon.png"
        alt="WhatsApp Icon"
        className="whatsapp-icon-style"
      />
      <div className="hover-message">&nbsp; WhatsApp us &nbsp;</div>
    </div>
  );
}
