// Navbar.js
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useNavigate } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const getMenuIcon = () => {
    return isMobileMenuOpen ? (
      <span className="cross-icon">&#10006;</span>
    ) : (
      <span className="navbar-toggle-icon">&#9776;</span>
    );
  };
  const handlePhoneNumberClick = () => {
    const phoneNumber = "3041718000";
    const whatsappUri = `https://wa.me/${phoneNumber}`;

    if ("launchApp" in navigator) {
      navigator.launchApp(whatsappUri);
    } else {
      window.open(whatsappUri, "_blank");
    }
  };
  const handleEmailClick = () => {
    const emailAddress = "info@glasscopaint.com";
    const mailtoUri = `mailto:${emailAddress}`;

    if ("launchApp" in navigator) {
      navigator.launchApp(mailtoUri);
    } else {
      window.location.href = mailtoUri;
    }
  };
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg ${isScrolled ? "scrolled" : ""}`}
      >
        <div className="container custom-container mx-0">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/Glassco Paint Logo.png"}
              alt="Icon"
              className={`homelogo`}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </div>

          <button className="mobile-menu-btn" onClick={toggleMobileMenu}>
            {getMenuIcon()}
          </button>
          <div
            className={`navbar-nav align-items-center custom-link ${
              isMobileMenuOpen ? "mobile-menu-open" : "mobile-menu-closed"
            }`}
          >
            <NavLink
              to="/"
              className="nav-link"
              activeClassName="active"
              onClick={toggleMobileMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/products"
              className="nav-link"
              activeClassName="active"
              onClick={toggleMobileMenu}
            >
              Products
            </NavLink>
            <NavLink
              to="/shade-card"
              className="nav-link"
              activeClassName="active"
              onClick={toggleMobileMenu}
            >
              Shade Card
            </NavLink>
            <NavLink
              to="/about"
              className="nav-link"
              activeClassName="active"
              onClick={toggleMobileMenu}
            >
              About Us
            </NavLink>
            <NavLink
              to="/contact"
              className="nav-link"
              activeClassName="active"
              onClick={toggleMobileMenu}
            >
              Contact Us
            </NavLink>
          </div>

          {isMobile === false && (
            <div className=" contact-us">
              <div className=" number">
                <img
                  className="whats-app-circle"
                  src="whatsapp-cirlce.png"
                  alt="wahtsapp-icon"
                />
                &nbsp;&nbsp;{" "}
                <span onClick={() => handlePhoneNumberClick()}>
                  0304 1718000{" "}
                </span>
              </div>
              <div className=" mail">
                <img
                  className="mail-circle"
                  src="gmail-circle.png"
                  alt="gmail-icon"
                />
                &nbsp;&nbsp;{" "}
                <span onClick={() => handleEmailClick()}>
                  info@glasscopaint.com
                </span>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
