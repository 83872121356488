import React, { useEffect, useState } from "react";
import "./headingtext.css";

export default function HeadingText({text}) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
     
      setIsVisible(true);
    }, []);
  return (
    <div className="heading-text-container">
      <div className={`heading-text ${isVisible ? 'visible' : ''}`}>{text}</div>
    </div>
  );
}
