import "./App.css";
import { Suspense, lazy } from "react";
import TopBar from "./components/top-bar/TopBar";
import Navbar from "./components/nav-bar/NavBar";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import WhatsAppIcon from "./components/whatsapp-icon/WhatsAppIcon";
import SpinnerFullPage from "./components/SpinnerFullPage"
// import Home from "./pages/home/Home";
import AboutUs from "./pages/about-us/AboutUs";
import ProductsPage from "./pages/products-page/ProductsPage";
import ContactUs from "./pages/contact-us/ContactUs";
import ShadeColour from "./pages/shade-colour/ShadeColour";

const Home = lazy(() => import("./pages/home/Home"));
// const ProductsPage = lazy(() => import("./pages/products-page/ProductsPage"));
// const ContactUs = lazy(() => import("./pages/contact-us/ContactUs"));
// const ShadeColour = lazy(() => import("./pages/shade-colour/ShadeColour"));

function App() {
  return (
    <div>
      <Suspense fallback={<SpinnerFullPage/>}>
      <TopBar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/shade-card" element={<ShadeColour />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <WhatsAppIcon />
      <Footer />
      </Suspense>
    </div>
  );
}

export default App;
