import React from "react";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <footer className="footer">
        <div className="footer-icon" style={{ cursor: "pointer" }}>
          <img
            src="Glassco Paint Logo.png"
            alt="footer-logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="footer-contact">
          <p className="footer-header">GET IN TOUCH</p>
          <b style={{ fontSize: 20 }}> 📧 info@glasscopaint.com </b>
          <b style={{ fontSize: 20 }}> 📞 Mobile: +923041718000</b>
          <div className="social-icons">
            {/* Add Facebook and Instagram icons */}
            <a
              href="https://www.facebook.com/profile.php?id=61554502400030&mibextid=sCpJLy"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-bg"
            >
              <FontAwesomeIcon icon={faFacebook} className="icon-footer" />
            </a>
            <a
              href="https://www.instagram.com/glasscopaints?igsh=ZWw2cnBtMzd6c2Ro&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-bg"
            >
              <FontAwesomeIcon icon={faInstagram} className="icon-footer" />
            </a>
          </div>
        </div>
        <div className="footer-links">
          <p className="footer-header">QUICK LINKS</p>
          <a href="/">Home</a>
          <a href="/about">About Us</a>
          <a href="/products">Products</a>
          <a href="/contact">Contact Us</a>
        </div>
      </footer>
      <div
        style={{
          backgroundColor: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="copyright">
          <p>
            &copy; &nbsp; All rights reserved | Design & Developed By:
            <a href="#"> Logicwise </a>{" "}
          </p>
        </div>
      </div>
    </>
  );
}
