import React from "react";
import CategoryList from "../../components/category/CategoryList";

export default function ShadeColour() {
  const shadeColour = true;
  return (
    <div>
      <div className="header-image">
        <img src="home.jpg" alt="Header" />
      </div>
      <div>
        <CategoryList shadeColour={shadeColour} />
      </div>
    </div>
  );
}
