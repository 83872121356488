import React from "react";

const SpinnerFullPage = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
        zIndex: 9999, // Make sure the spinner stays on top of other content
      }}
    >
      <div style={{ position: "relative", width: "4rem", height: "4rem" }}>
        <div
          className="spinner-border"
          role="status"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            width: "4rem",
            height: "4rem",
            color: "black",
          }}
        ></div>
        <div
          style={{
            fontSize: "2rem",
            fontWeight: "bold",
            fontFamily:'-moz-initial',
            color: "black",
            position: "absolute",
            bottom: "0",
            width: 700,
            textAlign: "center",
          }}
        >
          Glassco paint Loading...
        </div>
      </div>
    </div>
  );
};

export default SpinnerFullPage;
