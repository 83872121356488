import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "react-responsive";
import "./top-bar.css";

const TopBar = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="text-black d-flex align-items-center top-bar">
      {isMobile === false && (
        <div className="left-side">
          <span style={{ fontSize: 19 }}>
            Unleash Your Imagination, Embrace Vibrancy.
          </span>
        </div>
      )}
      <div
        className={`d-flex ${
          isMobile ? "justify-content-center" : ""
        } right-side`}
      >
        <a
          href="https://www.facebook.com/profile.php?id=61554502400030&mibextid=sCpJLy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} className="icon" />
        </a>
        <a
          href="https://www.instagram.com/glasscopaints?igsh=ZWw2cnBtMzd6c2Ro&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} className="icon" />
        </a>
      </div>
    </div>
  );
};

export default React.memo(TopBar)
